<template>
  <div>
    <no-access-info-card v-if="showNoAccessInfoCard" />
    <div
      v-else
      class="d-flex flex-md-row mt-2 flex-column-reverse"
    >
      <div class="col-md-9 nopadding">
        <div v-if="course">
          <webinar-card
            v-for="item in course.data"
            :id="item.id"
            :key="item.id"
            :course="item.attributes"
            :subscribed="item.attributes.is_subscribed"
            :is_own="true"
            :can-show-image="showImages"
            @onImgLoad="loadedImages+= 1"
            @haveImage="imagesCount+= 1"
          />
          <div class="d-flex align-items-center justify-content-center">
            <b-pagination
              v-if="course.meta.pagination.total > course.meta.pagination.per_page"
              v-model="currentPage"
              :per-page="course.meta.pagination.per_page"
              :total-rows="course.meta.pagination.total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        <div
          v-if="!course || !course.data.length"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="@/assets/images/pages/course/empty-list.svg"
            alt="Buypass"
            class="d-inline-block mt-5"
          />
        </div>
      </div>
      <div class="col-md-3 pl-md-1 pr-md-0 p-0">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend
            class="no-border"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input
            v-model="search"
            type="search"
            class="no-border"
            :placeholder="$t('Search...')"
          />
        </b-input-group>
        <filter-card page="myCourse" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BPagination,
  BImg,
} from 'bootstrap-vue'
import { debounce } from 'vue-debounce'
import FilterCard from '@/components/course/FilterCard.vue'
import WebinarCard from '@/components/course/WebinarCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    FilterCard,
    BPagination,
    BImg,
    WebinarCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    showImages() {
      return this.loadedImages >= this.course.data.filter(el => el.attributes.image).length
    },
    search: {
      get() {
        return this.$store.getters['myCourse/search']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('myCourse/setSearch', search)
      }, 1000),
    },
    query() {
      return this.$store.getters['myCourse/query']
    },
    currentPage: {
      get() {
        return this.$store.getters['myCourse/pageNumber']
      },
      set(pageNumber) {
        this.$store.commit('myCourse/setPageNumber', pageNumber)
      },
    },
    course() {
      return this.$store.getters['myCourse/course']
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompany() {
      if (this.isAuthenticated) return 'id' in this.user.active_business_profile
      return false
    },
    showNoAccessInfoCard() {
      if (!this.isAuthenticated) return false
      if (this.representACompany) return false
      if (this.user.user_info.approved_hrp !== 2) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.query.filter': {
      handler() {
        this.$store.dispatch('myCourse/fetchCourses')
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.$store.commit(
        'myCourse/setQuery',
        JSON.parse(this.$route.query.filter),
      )
    }
    if (!this.showNoAccessInfoCard) this.$store.dispatch('myCourse/fetchCourses')
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('filterData/fetchCounties')
  },
  beforeDestroy() {
    this.$store.commit('myCourse/clearFilters')
  },
  methods: {
    updateQuery(query) {
      this.$router.push({
        name: 'own_courses',
        query: { filter: JSON.stringify(query) },
      })
    },
  },
}
</script>
